import {find, findIndex} from "lodash";

// handy to use in case when you just want to check if any one element of arr is present in haystack
export const equalsAny = (haystack, arr) => {
return arr.some(function (v) {
    return haystack.indexOf(v) >= 0;
});
};

export const hasModuleEnabled = (features, module) => {
   let feature = find(features, {module: module});
   return feature?.enabled;
}

export const hasAnyModuleEnabled = (features, modules) => {
    for(let i = 0; i < modules.length; i++){
        let feature = find(features, {module: modules[i]});
        if(feature?.enabled){
            return true;
        }
    }
    return false;
}

// handy to use in case when you want to update or add add new elements in haystack from elements of arr
export const getUpdatedList = (haystack, arr) => {
    let updatedHaystack = [...haystack]; // clone of haystack
    arr.map((v) => {
        var i = findIndex(haystack, {id: v.id});
        if(i >= 0) {
            updatedHaystack.splice(i, 1, arr[i]);
        } else {
            updatedHaystack.push(v);
        }
    })
    return updatedHaystack;
}

// handy to use when you want to get unique values of specific key in array of objects
export const uniqueKeyValues = (arr, key) => {
    const values = [... new Set(arr.map((obj) => {return obj[key]}))];
    return values.sort();
}


export const getDateObject = (date) => {
    try{
        if (!date) {
            return new Date();
        }
        const [year, month, day] = date.split('-').map(Number);
        let value = new Date(year, month - 1, day);
        return value;
    }catch (e){
        console.error(e);
    }
}

export const parseDateToString = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0'); // Pad start with '0' if month or day is single digit

    return `${year}-${month}-${day}`;
}

// to trigger the function after a certain amount of time
export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

export const downloadPDF = async (documentNumber, type) => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    let alink = document.createElement('a');
    alink.href = process.env.REACT_APP_APIBASEURL + `/api/${type}/preview/${documentNumber}?token=${user?.accessToken}`;
    alink.download = 'SamplePDF.pdf';
    alink.target = "_blank";
    alink.click();
}

export const getFilterClassName = (filterName) => {
  if(filterName == 'Delayed' || filterName == 'Expired'){
    return 'bg-soft-warning bg-warning';
  }
  if(filterName == 'Cancelled'){
    return 'bg-soft-danger bg-danger';
  }
  if(filterName == 'Ongoing'){
    return 'bg-soft-info bg-info';
  }
  if(filterName == 'Invoiced' || filterName == 'Accepted'){
    return 'bg-soft-success bg-success';
  }

  return null;
}

export const getPriorityLabelClassName = (priority) => {
  if(priority == 'High'){
    return 'bg-soft-danger bg-danger';
  }
  if(priority == 'Medium High'){
    return 'bg-soft-warning bg-warning';
  }
  if(priority == 'Medium'){
    return 'bg-soft-info bg-info';
  }
  if(priority == 'Medium Low'){
    return 'bg-soft-success bg-success';
  }
  if(priority == 'Low'){
    return 'bg-soft-primary bg-primary';
  }

  return 'bg-soft-primary bg-primary';
}

export const preventNumberInputScroll = (e) => {
    // Prevent the input value change
    e.target.blur()
  
    // Prevent the page/container scrolling
    e.stopPropagation()
  
    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

export const validateNumber = (e) => {
    // keys for backspace, left and right arrow, ctrl and delete
    const excludedKeys = [8, 37, 39, 46];
    const keyCode = e.which;
    const validInput = (keyCode >= 48 && keyCode <= 57) 
        || (keyCode >= 96 && keyCode <= 105)
        || excludedKeys.includes(keyCode);
  if (!validInput) {
      e.preventDefault();
  }
};
